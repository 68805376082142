import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bloc from "../components/Ebooks/Bloc"
import Telechargement from "../components/Ebooks/Telechargement"
import BannerV2Article from "../components/elements/BannersV2/BannerV2Article"

const Ebook = ({ data, location }) => {
  const { ebooks } = data

  const bannerLeftCardContent = {
    theme: ebooks.acf.theme_ebook,
    type: ebooks.acf.type_ebook,
    title: ebooks.acf.title,
    publicationDate: ebooks.acf.date_de_publication,
    level: ebooks.acf.niveau_ebook,
  }
  return (
    <Layout
      location={"/livres-blancs/"}
      locationBis={location.pathname}
      crumbLabel="Livres blancs"
      crumbLabelBis={ebooks.acf.titre_banniere}
      locationGTM={location.href}
    >
      <SEO
        title={ebooks.acf.title}
        description={ebooks.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2Article bannerLeftCardContent={bannerLeftCardContent} />
      <Bloc
        image={ebooks.acf.image_ebook.localFile.childImageSharp.fluid}
        title={ebooks.acf.titre_details}
        text={ebooks.acf.texte_details}
        flexImage="1 0 50%"
        flexText="1 0 50%"
        backgroundText="greyLighter"
        orderImage="2"
        ordertext="1"
        orderImageMobile="2"
        ordertextMobile="1"
        isSynergyImage
      />
      <Telechargement
        title="Télécharger le livre blanc"
        fichier={ebooks.acf.fichier_pdf}
        titleEbook={ebooks.acf.titre_banniere}
      />
    </Layout>
  )
}

export default Ebook

export const query = graphql`
  query($id: String!) {
    ebooks: wordpressWpEbooks(id: { eq: $id }) {
      acf {
        title
        theme_ebook
        type_ebook
        niveau_ebook
        date_de_publication
        meta_description
        titre_banniere
        texte_banniere
        titre_details
        texte_details
        image_ebook {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        fichier_pdf {
          source_url
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
  }
`
