import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Parser from "html-react-parser"

import globalVariables from "../globalVariables"
import Section from "../elements/Section"
import { TitleH2 } from "../elements/Title"
import ButtonAnchor from "../elements/Anchor/ButtonAnchor"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const WrapperImage = styled.div`
  background-color: ${props => props.theme.secondary};
  position: relative;
  @media (min-width: ${globalVariables.minTablet}) {
    display: block;
    flex: 1 1 100%;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: 1 0 50%;
  }
  @media (min-width: ${globalVariables.bigDesktop}) {
    max-height: 800px;
  }
`

const Intro = styled.div`
  background-color: ${props => props.theme.greyLighter};
  padding: 50px 20px;
  border-top: 1px solid white;

  @media (min-width: ${globalVariables.minTablet}) {
    border-top: none;
    padding: 70px 65px 60px;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .title1.trait::after {
      bottom: -34px;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: 1 0 50%;
  }
`

const Text = styled.div`
  ul {
    margin-left: 2rem;
    margin-bottom: 2rem;
  }
  margin-top: 50px;
`

const Bloc = ({ title, text, image }) => {
  return (
    <Section padding="0">
      <Container>
        <Intro>
          <TitleH2 className="title1 trait" traitLeft="-65px">
            {title}
          </TitleH2>
          <Text>{Parser(text)}</Text>
          <ButtonAnchor
            title="Télécharger le livre blanc"
            anchor="section-form-telechargement"
          />
        </Intro>
        <WrapperImage>
          <Img fluid={image} />
        </WrapperImage>
      </Container>
    </Section>
  )
}

export default Bloc
