import React, { useState } from "react"
import styled from "styled-components"

import Input from "../elements/Input"
import Textarea from "../elements/Textarea"
import Button from "../elements/Button"
import globalVariables from "../globalVariables"
import { validator } from "./validationRules"
import Dropdown2 from "../elements/Dropdown/index2"
import Fleche from "../../images/icons/fleche-grise.svg"

const FormStyled = styled.form`
  display: flex;
  flex-direction: ${props => (props.isSent ? "column" : "row")};
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 910px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1rem;
  margin-bottom: 0;
`

const Spacer = styled.div`
  flex: ${props => (props.flex ? props.flex : "1 1 100%")};
  padding: 10px 0;
  position: relative;
  button[type="submit"] {
    margin-top: 10px;
  }
  text-align: ${props => (props.textAlign ? "center" : null)};
  &.linkedin-url {
    position: relative;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 10px;
    &.linkedin-url {
      flex: 1 1 350px;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    &.linkedin-url {
      flex: 1 1 600px;
    }
  }
`
const ValidationMessage = styled.span`
  position: absolute;
  bottom: 11px;
  left: 32px;
  font-size: 13px;
  color: ${props => props.theme.error};
  opacity: ${props => (props.isInvalid ? "1" : "0")};
  padding: 2px 5px;
`

const SuccessMessage = styled.p`
  color: ${props => props.theme.white};
  margin-bottom: 2rem;
`

const Select = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  /* font-size: 14px; */
  background: white;
  height: 55px;
  padding: 0 10px 0 25px;
  border: solid 1px #aeaeae;

  img {
    transition: all 0.2s ease-in-out;
    transform: ${props => (props.isOpen ? "rotate(90deg)" : "rotate(270deg)")};
  }
  @media (min-width: ${globalVariables.minTablet}) {
    min-width: 350px;
  }
`

const Arrow = styled.img`
  margin-left: 30px;
  width: 8px;
`

const Info = styled.p`
  color: white;
  font-style: italic;
  font-size: 14px;
`

const EbookForm = ({ fichier, titleEbook }) => {
  const initialState = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
    website: "",
    profil: "Tous",
    isSent: false,
    validation: validator.valid(),
  }
  const [formData, setFormData] = useState({
    ...initialState,
  })
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const handleInputChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleClickProfil = (value, callback) => {
    setFormData({
      ...formData,
      profil: value,
    })
    callback()
  }

  const profilList = ["Annonceur", "Agence", "Prestataire", "Autre"]

  const handleSubmit = event => {
    event.preventDefault()

    const validation = validator.validate(formData)
    setFormData({
      ...formData,
      validation,
    })
    setIsFormSubmitted(true)

    if (validation.isValid) {
      // envoi les donnees du formulaire

      const encode = data => {
        return Object.keys(data)
          .map(
            key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
          )
          .join("&")
      }
      fetch(event.target.action, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "telechargement-livre-blanc",
          titleEbook,
          ...formData,
        }),
      })
        .then(() => {
          setFormData({ ...initialState, isSent: true })
        })
        .catch(error => alert(error))
    }
  }

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    website,
    message,
    profil,
    validation,
  } = formData
  // if the form has been submitted at least once, then check validity every time we render
  // otherwise just use what's in state
  const copyData = { ...formData }
  const formValidation =
    isFormSubmitted && !formData.isSent
      ? validator.validate(copyData)
      : validation

  return (
    <FormStyled
      onSubmit={handleSubmit}
      data-netlify="true"
      name="telechargement-livre-blanc"
      method="POST"
      netlify-honeypot="bot-field"
      isSent={formData.isSent}
    >
      {!formData.isSent && (
        <React.Fragment>
          <Spacer flex="1 1 50%">
            <Input
              type="text"
              placeholder="Prénom*"
              value={firstName}
              handleChange={e => handleInputChange(e)}
              name="firstName"
              required
              fullWidth
              id="firstName"
            />
            <input
              type="hidden"
              name="form-name"
              value="telechargement-livre-blanc"
            />
            <input
              type="text"
              value={titleEbook}
              name="titleEbook"
              id="titleEbook"
              style={{ display: "none" }}
            />
            <ValidationMessage isInvalid={formValidation.firstName.isInvalid}>
              {formValidation.firstName.message}
            </ValidationMessage>
          </Spacer>
          <Spacer flex="1 1 50%">
            <Input
              type="text"
              placeholder="Nom*"
              value={lastName}
              handleChange={e => handleInputChange(e)}
              name="lastName"
              required
              fullWidth
              id="lastName"
            />
            <ValidationMessage isInvalid={formValidation.lastName.isInvalid}>
              {formValidation.lastName.message}
            </ValidationMessage>
          </Spacer>
          <Spacer flex="1 1 50%">
            <Input
              type="tel"
              placeholder="Téléphone*"
              value={phoneNumber}
              handleChange={e => handleInputChange(e)}
              name="phoneNumber"
              fullWidth
              id="phoneNumber"
            />
            <ValidationMessage isInvalid={formValidation.phoneNumber.isInvalid}>
              {formValidation.phoneNumber.message}
            </ValidationMessage>
          </Spacer>
          <Spacer flex="1 1 50%">
            <Input
              type="email"
              placeholder="E-mail*"
              value={email}
              handleChange={e => handleInputChange(e)}
              name="email"
              required
              fullWidth
              id="email"
            />
            <ValidationMessage isInvalid={formValidation.website.isInvalid}>
              {formValidation.website.message}
            </ValidationMessage>
          </Spacer>
          <Spacer flex="1 1 50%">
            <Input
              type="url"
              placeholder="Site internet*"
              value={website}
              handleChange={e => handleInputChange(e)}
              name="website"
              required
              fullWidth
              id="website"
            />
            <ValidationMessage isInvalid={formValidation.website.isInvalid}>
              {formValidation.website.message}
            </ValidationMessage>
          </Spacer>
          <Spacer flex="1 1 50%">
            <Dropdown2
              top="2.5rem"
              left="-20px"
              triggerComponent={({ handleClick, isOpen }) => {
                return (
                  <Select onClick={handleClick} isOpen={isOpen}>
                    {profil === "Tous" ? "Votre profil*" : profil}
                    <Arrow src={Fleche} alt="choisir votre profil" />
                  </Select>
                )
              }}
              items={profilList}
              value={profil}
              handleClickSelect={handleClickProfil}
            />
          </Spacer>

          <Spacer>
            <Textarea
              type="text"
              placeholder="Votre besoin*"
              value={message}
              handleChange={e => handleInputChange(e)}
              name="message"
              required
              fullWidth
              id="message"
            />
            <ValidationMessage isInvalid={formValidation.message.isInvalid}>
              {formValidation.message.message}
            </ValidationMessage>
          </Spacer>
          <Info>* Tous les champs sont requis</Info>

          <Spacer textAlign="center">
            <Button
              title="Envoyer"
              type="submit"
              colors="primary"
              id="bouton-envoyer-livre-blanc"
            />
          </Spacer>
        </React.Fragment>
      )}
      {formData.isSent && (
        <React.Fragment>
          <SuccessMessage>
            Merci, vous pouvez télécharger votre livre blanc. Bonne lecture !
          </SuccessMessage>
          <Button
            title="Télécharger"
            download
            colors="primary"
            id="bouton-telecharger-livre-blanc"
            as="external-link"
            href={fichier.source_url}
            target="_blank"
          />
        </React.Fragment>
      )}
    </FormStyled>
  )
}

export default EbookForm
