import React, { useEffect, useState, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import styled from "styled-components"
import globalVariables from "../../globalVariables"

const DropdownWrapperStyled = styled.div`
  position: relative;
  @media (max-width: ${globalVariables.maxMobile}) {
    width: 100%;
  }
`

const DropdownContentStyled = styled.div`
  background: white;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 3rem;
  left: 0;
  width: 100%;
  transform: translateY(-2em);
  z-index: 2;
  transition: all 0.4s cubic-bezier(0, 1, 0.5, 1) 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  @media (min-width: ${globalVariables.minTablet}) {
    top: 2.4rem;
  }
`

const DropdownContent = styled.ul`
  margin-left: 0;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #dedede;
`
const Item = styled.li`
  font-size: 14px;
  text-transform: uppercase;
  list-style: none;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: ${props => (props.isActive ? props.theme.primary : null)};
  font-weight: ${props => (props.isActive ? "700" : null)};
  &:hover {
    color: ${props => props.theme.primary};
  }
`

export const Dropdown2 = ({
  triggerComponent,
  top,
  left,
  handleClickSelect,
  value,
  items,
}) => {
  const dropdownNode = useRef()
  const triggerNode = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [triggerHeight, setTriggerHeight] = useState(0)

  const handleClick = e => {
    if (dropdownNode && dropdownNode.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  useEffect(() => {
    if (triggerNode) {
      const height = triggerNode.current.clientHeight
      setTriggerHeight(height)
    }
  }, [])

  const triggerElement = Component => {
    return (
      <Component
        isOpen={isOpen}
        handleClick={e => {
          setIsOpen(!isOpen)
        }}
      />
    )
  }

  return (
    <DropdownWrapperStyled ref={dropdownNode}>
      <div ref={triggerNode}>{triggerElement(triggerComponent)}</div>

      <CSSTransition
        in={isOpen}
        timeout={0}
        classNames="dropdown"
        unmountOnExit
      >
        <DropdownContentStyled height={triggerHeight} left={left} top={top}>
          <DropdownContent>
            <Item
              isActive={value === "Tous"}
              onClick={() => handleClickSelect("Tous", () => setIsOpen(false))}
            >
              Tous
            </Item>
            {items.map((item, index) => (
              <Item
                key={index}
                isActive={value === item}
                onClick={() => handleClickSelect(item, () => setIsOpen(false))}
              >
                {item}
              </Item>
            ))}
          </DropdownContent>
        </DropdownContentStyled>
      </CSSTransition>
    </DropdownWrapperStyled>
  )
}

export default Dropdown2
export * from "./DropdownMenu"
