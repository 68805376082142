import React from "react"

import Section from "../elements/Section"
import Container from "../elements/Container"
import { TitleH2 } from "../elements/Title"
import EbookForm from "./EbookForm"

const Telechargement = ({ title, fichier, titleEbook }) => {
  return (
    <Section
      color="primary"
      border
      id="section-form-telechargement"
      paddingMobile="70px 0 60px"
    >
      <Container>
        <TitleH2 className="title1" color="white" position="center">
          {title}
        </TitleH2>
        <EbookForm fichier={fichier} titleEbook={titleEbook} />
      </Container>
    </Section>
  )
}

export default Telechargement
